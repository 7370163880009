import { defineStore } from "pinia";
import {
  TrainingImportApi,
  type ImportsResponse,
  type UploadTrainingsCSVRequest,
  TrainingsLmsApi,
  TrainingsApi,
} from "@simbelapp/trainings-sdk";
import { useCatalogApi } from "~~/composables/catalog/useCatalogApi";
import { useTrainingsApi } from "~~/composables/trainings/useTrainingsApi";
import type { ITrainingsStore } from "~~/utils/interfaces/trainings-interface";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { useSDKApi, useSDKApiOlder } from "~/composables/api/useSDKApi";

export const useTrainingsStore = defineStore("trainingsStore", {
  state: (): ITrainingsStore => {
    return {
      trainingCatalog: null,
      trainings: null,
      training_details: null,
      showAddCatalog: false,
      training_page_details: null,
      training_page_review: null,
      currentUrl: null,
    };
  },
  getters: {},
  actions: {
    async createUpdateTraining(body: any) {
      const trainings = useTrainingsApi();
      const feedback = useFeedback();

      try {
        const { data } = await trainings.createUpdate({ ...body });
        return data;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async createOwnTraining(body: any) {
      const trainings = useTrainingsApi();
      const feedback = useFeedback();

      try {
        const { data } = await trainings.createOwnTraining({ ...body });
        return data;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async fetchTrainingDetails(trainingId?: string) {
      const catalogApi = useCatalogApi();
      const { data } = await catalogApi.fetchEmployeeCatalog({ trainingId });
      if (data.value) {
        this.trainingCatalog = data.value.data[0];
      }
    },
    async fetchAllTrainings() {
      const trainingsApi = useTrainingsApi();
      const { data } = await trainingsApi.fetchTrainings();
      if (data.value) {
        this.trainings = data.value;
      }
    },
    async fetchOwnPartnerTrainingDetails(trainingId: string) {
      const trainings = useTrainingsApi();
      const { data } = await trainings.fetchOwnPartnerTrainingDetails(trainingId);

      if (data.value) {
        this.training_details = data.value;
      }
      return data;
    },
    async fetchTrainingPageDetails(trainingId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsApi);
      const response = await apiInstance.getTrainingDetails({ trainingId });
      this.training_page_details = response;
    },

    async checkIfTrainingIsLms(trainingId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);

      const response = await apiInstance.checkIfTrainingIsLms({ trainingId });
      return response.isLms;
    },

    async fetchTrainingPedagogicalReports(trainingId: string) {
      const trainings = useTrainingsApi();
      const { data } = await trainings.fetchTrainingPedagogicalReports(trainingId);

      if (data.value) {
        this.training_page_review = data.value;
      }
      return data;
    },
    async updateCreateTrainingAdmin(trainingId: string, adminIds: string[]) {
      const trainings = useTrainingsApi();
      const feedback = useFeedback();

      try {
        const { data } = await trainings.updateCreateTrainingAdmin(trainingId, adminIds);

        return data;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async importTrainings(uploadUsersCSV: UploadTrainingsCSVRequest): Promise<ImportsResponse | null> {
      const feedback = useFeedback();
      const apiInstance = await useSDKApiOlder(MicroserviceEnum.TRAININGS, TrainingImportApi);
      const response: ImportsResponse | null = await apiInstance
        .uploadTrainingsCSV(uploadUsersCSV)
        .catch(async (error) => {
          const errorResponse = await error.response.json();
          if (errorResponse.message.includes("Parallel import in progress")) {
            feedback.error("Un import est déjà en cours, veuillez attendre avant de relancer", "small");
            return null;
          } else {
            feedback.error("Erreur Interne", "small");
            return null;
          }
        });
      if (response) {
        return response;
      } else {
        return null;
      }
    },
  },
});
