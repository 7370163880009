import { required } from "@vuelidate/validators";

import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useCreateProjectStore } from "~/store/project/create-project.store";
import { SessionItemTypeEnum } from "~/utils/enums/session-items.enums";
import { useCreateProjectCoreStore } from "~/store/project/create-project-core.store";

export const useProjects = () => {
  function getNav(): any[] {
    return [
      {
        name: "Formation",
      },
      {
        name: "Participants",
      },
      {
        name: "Session",
      },
      {
        name: "Financement",
      },
    ];
  }

  function getNavRegulation(): any[] {
    return [
      {
        name: "Formation",
      },
      {
        name: "Inscription",
      },
      {
        name: "Dates",
      },
      {
        name: "Participants",
      },
      {
        name: "Financement",
      },
    ];
  }

  return { getNav, getNavRegulation };
};

export const useProjectsValidation = () => {
  async function stepTraining() {
    const projectStore = useCreateProjectCoreStore();
    const { project } = storeToRefs(projectStore);

    const rules = computed(() => {
      return {
        training_id: {
          // new_session_items_format: validateSessionsDraftTraining,
          // duration_hours: validateDurationDraftTraining,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.training);
    await v$.value.$validate();

    return v$;
  }

  async function stepSessionItem() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    const rules = computed(() => {
      return {
        session_item_type: {
          required,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.session_item);
    await v$.value.$validate();

    return v$;
  }

  async function stepDate() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function newDateOrSessionSelected(_: any, siblings: any) {
      return (
        !!siblings.session_id ||
        (!!siblings.session_draft.start_date &&
          !!siblings.session_draft.end_date &&
          !!project.value.training.training_id?.duration_hours &&
          !!project.value.session_item.session_item_id?.format) ||
        !!siblings.session_draft_id
      );
    }

    const rules = computed(() => {
      return {
        session_id: {
          newDateOrSessionSelected,
        },
        session_draft: {
          start_date: {},
          end_date: {},
          format: {},
          duration: {},
          newDateOrSessionSelected,
        },
        session_draft_id: {
          newDateOrSessionSelected,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.session);
    await v$.value.$validate();

    return v$;
  }

  async function stepAttendees() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function anAttendeeIsSelected(_: any, siblings: any) {
      if (project.value.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL)
        return siblings.users_id?.length > 0;
      else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        users_id: {
          anAttendeeIsSelected,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.users);
    await v$.value.$validate();

    return v$;
  }

  async function stepFinancing() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function validateFinancing(_: any, siblings: any) {
      if (project.value.session_item.session_item_type !== SessionItemTypeEnum.INDIVIDUAL) {
        return (
          Number(siblings.price_excl_tax ?? 0) ===
          Number(siblings.opco_excl_tax ?? 0) +
            Number(siblings.cpf_excl_tax ?? 0) +
            Number(siblings.budget_excl_tax ?? 0)
        );
      } else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        price_excl_tax: { validateFinancing },
        budget_excl_tax: { validateFinancing },
        opco_excl_tax: { validateFinancing },
        cpf_excl_tax: { validateFinancing },
      };
    });

    const v$ = useVuelidate(rules, project.value.financing);
    await v$.value.$validate();

    return v$;
  }

  async function validateConfirmProject() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function validateFinancing(_: any, siblings: any) {
      if (project.value.session_item.session_item_type !== SessionItemTypeEnum.INDIVIDUAL) {
        return (
          Number(siblings.price_excl_tax) >= 0 &&
          Number(siblings.price_excl_tax ?? 0) ===
            Number(siblings.opco_excl_tax ?? 0) +
              Number(siblings.cpf_excl_tax ?? 0) +
              Number(siblings.budget_excl_tax ?? 0)
        );
      } else {
        return true;
      }
    }

    function newDateOrSessionSelected(_: any, siblings: any) {
      return (
        !!siblings.session_id ||
        (!!siblings.session_draft.start_date &&
          !!siblings.session_draft.end_date &&
          !!project.value.training.training_id?.duration_hours &&
          !!project.value.session_item.session_item_id?.format) ||
        (!!siblings.session_draft_id &&
          !!siblings.session_draft_id?.start_date &&
          !!siblings.session_draft_id?.end_date)
      );
    }

    function anAttendeeIsSelected(_: any, siblings: any) {
      if (project.value.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL)
        return siblings.users_id?.length > 0;
      else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        training: {
          training_id: {
            training: required,
          },
        },
        session_item: {
          session_item_id: {
            session_item_id: { required },
          },
          session_item_type: { required },
        },
        session: {
          session_id: {
            newDateOrSessionSelected,
          },
          session_draft: {
            start_date: {},
            end_date: {},
            format: {},
            duration: {},
            newDateOrSessionSelected,
          },
          session_draft_id: {
            newDateOrSessionSelected,
          },
        },
        users: {
          users_id: {
            anAttendeeIsSelected,
          },
        },
        financing: {
          price_excl_tax: { validateFinancing },
          budget: { validateFinancing },
          opco: { validateFinancing },
          cpf: { validateFinancing },
        },
      };
    });

    const v$ = useVuelidate(rules, project.value);
    await v$.value.$validate();

    return v$;
  }

  return {
    stepSessionItem,
    stepTraining,
    stepDate,
    stepAttendees,
    stepFinancing,
    validateConfirmProject,
  };
};

export const useRegulationValidation = () => {
  async function stepTraining() {
    function SomethingIsSelected(_: any, siblings: any) {
      return !!siblings.training_id;
    }

    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    const rules = computed(() => {
      return {
        training_id: {
          SomethingIsSelected,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.training);
    await v$.value.$validate();

    return v$;
  }

  async function stepSessionItem() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    const rules = computed(() => {
      return {
        session_item_type: {
          required,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.session_item);
    await v$.value.$validate();

    return v$;
  }

  async function stepDate() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function newDateOrSessionSelected(_: any, siblings: any) {
      return (
        !!siblings.session_id ||
        (!!siblings.session_draft.start_date &&
          !!siblings.session_draft.end_date &&
          !!project.value.training.training_id?.duration_hours &&
          !!project.value.session_item.session_item_id?.format) ||
        !!siblings.session_draft_id
      );
    }

    const rules = computed(() => {
      return {
        session_id: {
          newDateOrSessionSelected,
        },
        session_draft: {
          start_date: {},
          end_date: {},
          format: {},
          duration: {},
          newDateOrSessionSelected,
        },
        session_draft_id: {
          newDateOrSessionSelected,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.session);
    await v$.value.$validate();

    return v$;
  }

  async function stepAttendees() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function anAttendeeIsSelected(_: any, siblings: any) {
      if (project.value.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL)
        return siblings.users_id?.length > 0;
      else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        users_id: {
          anAttendeeIsSelected,
        },
      };
    });

    const v$ = useVuelidate(rules, project.value.users);
    await v$.value.$validate();

    return v$;
  }

  async function stepFinancing() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function validateFinancing(_: any, siblings: any) {
      if (project.value.session_item.session_item_type) {
        return (
          Number(siblings.price_excl_tax) >= 0 &&
          Number(siblings.price_excl_tax ?? 0) ===
            Number(siblings.opco_excl_tax ?? 0) +
              Number(siblings.cpf_excl_tax ?? 0) +
              Number(siblings.budget_excl_tax ?? 0)
        );
      } else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        price_excl_tax: { validateFinancing },
        budget_excl_tax: { validateFinancing },
        opco_excl_tax: { validateFinancing },
        cpf_excl_tax: { validateFinancing },
      };
    });

    const v$ = useVuelidate(rules, project.value.financing);
    await v$.value.$validate();

    return v$;
  }

  async function validateRegulation() {
    const projectStore = useCreateProjectStore();
    const { project } = storeToRefs(projectStore);

    function validateFinancing(_: any, siblings: any) {
      if (project.value.session_item.session_item_type) {
        return (
          Number(siblings.price_excl_tax) >= 0 &&
          Number(siblings.price_excl_tax ?? 0) ===
            Number(siblings.opco_excl_tax ?? 0) +
              Number(siblings.cpf_excl_tax ?? 0) +
              Number(siblings.budget_excl_tax ?? 0)
        );
      } else {
        return true;
      }
    }

    function newDateOrSessionSelected(_: any, siblings: any) {
      return (
        !!siblings.session_id ||
        (!!siblings.session_draft.start_date &&
          !!siblings.session_draft.end_date &&
          !!project.value.training.training_id?.duration_hours &&
          !!project.value.session_item.session_item_id?.format) ||
        (!!siblings.session_draft_id &&
          !!siblings.session_draft_id?.start_date &&
          !!siblings.session_draft_id?.end_date)
      );
    }

    function anAttendeeIsSelected(_: any, siblings: any) {
      if (project.value.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL)
        return siblings.users_id?.length > 0;
      else {
        return true;
      }
    }

    const rules = computed(() => {
      return {
        training: {
          training_id: {
            training: required,
          },
        },
        session_item: {
          session_item_id: {
            session_item_id: { required },
          },
          session_item_type: { required },
        },
        session: {
          session_id: {
            newDateOrSessionSelected,
          },
          session_draft: {
            start_date: {},
            end_date: {},
            format: {},
            duration: {},
            newDateOrSessionSelected,
          },
          session_draft_id: {
            newDateOrSessionSelected,
          },
        },
        users: {
          users_id: {
            anAttendeeIsSelected,
          },
        },
        financing: {
          price_excl_tax: { validateFinancing },
          budget_excl_tax: { validateFinancing },
          opco_excl_tax: { validateFinancing },
          cpf_excl_tax: { validateFinancing },
        },
      };
    });

    const v$ = useVuelidate(rules, project.value);
    await v$.value.$validate();

    return v$;
  }

  return {
    stepSessionItem,
    stepTraining,
    stepDate,
    stepAttendees,
    stepFinancing,
    validateRegulation,
  };
};
