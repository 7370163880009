export enum TrainingNavEnum {
  GENERAL,
  CONTENT,
  SESSION_ITEM,
  MODALITIES,
  SESSIONS,
}

export enum BuilderTrainingNavEnum {
  CONTENT,
  MODALITIES,
  ADVANCED,
  SESSIONS,
}

export enum TypeBuilderPanelEnum {
  ACTIVITY = "activity",
  TRAINING = "training",
}

export enum RegistrationType {
  SELF_REGISTRATION = "self_registration",
  LICENSE = "license",
  PARTNER_REGISTRATION = "partner_registration",
}

export enum TrainingTypeEnum {
  EXTERNAL = "external_training",
  INTERNAL = "internal_training",
  INTERNAL_BUILDER = "internal_builder_training",
}

export enum TrainingStatus {
  LIVE = "live",
  DRAFT = "draft",
}

export enum VisibleStatus {
  ALL = "all",
  HIDDEN = "hidden",
  GROUPS = "groups",
}
